import FlatList from "./FlatList"
import Banner from "./Banner"
import React from "react"
import TeamList from "./TeamList"
import Title from "./Title"
import References from "./References"
import Subscribe from "./Subscribe"
import BestFlatList from "./BestFlatList"
import { useState, useEffect } from "react";
import * as contentful from "contentful"



const Estepona=()=>{
    // State to store the fetched data
    const [data, setData] = useState(null);

  // State to track if the data is still loading
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        // Fetch data from API on component mount
        const fetchData = async () => {
          try {
            const client = contentful.createClient({
                space: 'cx70k9zsq5af',
                environment: 'master', // defaults to 'master' if not set
                accessToken: 'F2kMyptLDLJVSZnnmNilbGhjaos8o6eY12pIHBiXlzc'
              })
              
            //   client.getEntry('1D9Vo6dCTArZxuX0e6GVlq')
            //     .then((entry) => console.log(entry))
            //     .catch(console.error)
            
           await client.withoutUnresolvableLinks.getEntries()
                .then(
                    (entries) => {
                        console.log(entries)
                        // Store the data in state
                        setData(entries.items)
                    }
                )
                .catch(console.error)
            setLoading(false); // Set loading to false once data is fetched
          } catch (error) {
            console.error('Error fetching data:', error);
            setLoading(false);
          }
        };
    
        fetchData();
      }, []); // Empty dependency array ensures the effect runs only once

    return (
        <React.Fragment>
            <Banner page={"estepona"}/>
            <section className="section-best-estate"> 
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <Title title={"Estepona"} description={"Solkustens trädgård"} size= {2}/>
                            <p>
                            Estepona som även kallas Costad del Sol’s trädgård har blivit väldigt populärt bland svenskar på senare tid. Det är en mysig, genuint spansk stad med cirka 70 000 invånare. <br></br>
                            Stranden La Rada går genom hela staden och där kan man motionera eller promenera längs den långa strandpromenaden. Om man vill ha en lite lugnare och mer barnvänlig strand nära staden så är Playa del Cristo ett bra alternativ. <br></br>
                            I hamnen kan du se fiskebåtar, handla på fiskauktion vissa dagar i veckan eller bara sitta och njuta av en god drink eller mat på någon utav restaurangerna. I gamla stan hänger blommor längs gatorna i vackra färgglada krukor. <br></br>
                            Här finns restauranger, barer, caféer och shopping.
                            </p>
                            <Title title={"Lägenhet i Estepona"} size= {3}/>
                            <p> Lägenhet i Estepona kan man hitta i gamla stan med omnejd om man vill bo centralt. I hamnen finns också mycket lägenheter och många utav dom har utsikt över havet. </p>
                            <p>Ett område med lägenheter i Estepona som blivit populärt bland svenskar är Las Mesas som ligger lite uppe på en höjd. Här i området är det marknad varje onsdag och här ligger
                                även ett populärt gym och padelbanor. </p>
                          
                        </div>
                    </div>
                </div>
            </section>
            {loading ? (
                <p>Loading data...</p>
            ) : (
                <FlatList properties={data} />
            )}
            <BestFlatList/>
            <Subscribe/>
            <TeamList/>
            <References/>
        </React.Fragment>
    )
}

export default Estepona;