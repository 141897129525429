import React, { Fragment } from 'react'
// import Helmet from 'react-helmet'
import { stringify } from 'qs'
import { serialize } from 'dom-form-serializer'
// import './Form.css'
class Subscribe extends React.Component {
    static defaultProps = {
      name: 'Plats i Solen',
      subjectoffert: 'platsisolen.se', 
      action: 'https://formspree.io/f/xlepjnol', //test
      method: 'POST',
      successMessage: 'Nu är du tillagd. Tack!',
      errorMessage: 'Nått gick snett, var vänlig e-maila eller kontakta oss via WhatsApp.',
     
    }
  
    state = {
      alert: '',
      good: true,
      disabled: false,
    }
    handleSubmit = e => {
        e.preventDefault()
        if (this.state.disabled) return
        const form = e.target
        const data = serialize(form)
        if (data.info !== '') {
          this.setState({
            alert: "Informationen inte skickad (I). Tack!",
            good: true,
            disabled: true
          })
            return
        }
        if (data.matrix !== '') {
          this.setState({
            alert: "Informationen inte skickad (E). Tack!",
            good: true,
            disabled: true
          })
            return
        }
        if (data.email !== '') {
            this.setState({
              alert: "Informationen inte skickad (E). Tack!",
              good: true,
              disabled: true
            })
              return
          }
    
        delete data.matrix;
        delete data.info;
        delete data.email;
        data._replyto = data.ep;
        data.amne = this.subjectoffert;
        
        this.setState({ disabled: true })
        fetch(form.action, {
          method: 'POST',
          mode: 'no-cors',
          cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
          credentials: 'same-origin', // include, *same-origin, omit
          headers: {
            //'Content-Type': 'application/json'
            'Content-Type': 'application/x-www-form-urlencoded',
          },
          body:  stringify(data),
        })
          .then(res => {
            if (res.ok) {
              return res
            } else {
              console.log(res);
            // throw new Error('Network error')
            }
          })
          .then(() => {
            form.reset()
            this.setState({
              alert: this.props.successMessage,
              good: true,
              disabled: true
            })
          })
          .catch(err => {
            console.error(err)
            this.setState({
              disabled: true,
              good: false,
              alert: this.props.errorMessage
            })
          })
      }
    render() {
        const { name, subjectoffert, action } = this.props
   
    return (
        <Fragment>
            <section className="section-subscribe pt-5 pb-5">
                <div className="container">
                    <div className="row">
                        <section className="col-lg-6">
                            <div className={this.state.disabled? 'hidden' : ""}>
                                <p className="title">Nyhetsbrev</p>
                                <span className="sbs-description">Missa inte nya, spännande bostader. Prenumerera på vårat nyhetsbrev genom att skriva in din e-post adress.</span>
                            </div>
                            <div className={!this.state.disabled? 'hidden' : ""}>
                            {this.state.good && <p className="title">Tack!</p>}
                            {!this.state.good && <p className="title">Något gick fel :(</p>}
                                <span className="sbs-description">{ this.state.alert }</span>
                            </div>
                        </section>
                        <section className="col-lg-6">
                        <div className={this.state.disabled? 'hidden' : ""}>
                            <div className="d-flex align-items-center justify-content-between w-100 h-100">
                                <form
                                className="Form"
                                name={name}
                                action={action}
                                onSubmit={this.handleSubmit}
                                
                                >      
                                <div className="row w-100 m-0">
                                    <div className="col-lg-9">
                                    {!!subjectoffert && <input type="hidden" name="subject" value={subjectoffert} />}
                                    <input type="hidden" name="form-name" value={name} />
                                        
                                        <label className="Form--Shelf"><span>email</span>
                                        <input autoComplete="off" className="Form--Shelf" type="text" name="email" placeholder="your matrix" defaultValue="" />
                                        </label>
                                        
                                        <label className="Form--Shelf"><span>info</span>
                                        <input autoComplete="off" className="Form--Shelf" type="text" name="info" placeholder="your info" defaultValue="" />
                                        </label>
                                        
                                        <label className="Form--Shelf"><span>email</span>
                                        <input autoComplete="off" className="Form--Shelf" type="text" name="matrix"  placeholder="your matrix"  defaultValue="" />
                                        </label>
                                        
                                        <label className="Form--Shelf"><span>info</span>
                                        <input autoComplete="off" className="Form--Shelf" type="text" name="info" placeholder="your info" defaultValue="" />
                                        </label>
                                        <input type="email" required  name="ep" placeholder='Din E-post' className="w-100 sbs-area-inp" />
                                    </div>
                                    <div className="col-lg-3 d-flex align-items-center justify-content-between ">
                                        <input
                                            className="w-150 h-100 btn btn-dark"
                                            type="submit"
                                            value="Skicka"
                                            disabled={this.state.disabled}
                                        />
                                    </div>
                                </div>
                                </form>
                            </div>
                        </div>
                        </section>
                    </div>
                </div>
            </section>
        </Fragment>
    )
    }
}

export default Subscribe