import FlatList from "./FlatList"
import Banner from "./Banner"
import React from "react"
import Info from "./Info"
import References from "./References"
import Subscribe from "./Form/Subscribe"
// import BestFlatList from "./BestFlatList"
import { useState, useEffect } from "react"
import * as contentful from "contentful"
import Title from "./Title"



const Home=()=>{
    // State to store the fetched data
    const [data, setData] = useState(null);

  // State to track if the data is still loading
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        // Fetch data from API on component mount
        const fetchData = async () => {
          try {
            const client = contentful.createClient({
                space: 'cx70k9zsq5af',
                environment: 'master', // defaults to 'master' if not set
                accessToken: 'F2kMyptLDLJVSZnnmNilbGhjaos8o6eY12pIHBiXlzc'
              })
              
            //   client.getEntry('1D9Vo6dCTArZxuX0e6GVlq')
            //     .then((entry) => console.log(entry))
            //     .catch(console.error)
            
           await client.withoutUnresolvableLinks.getEntries()
                .then(
                    (entries) => {
                        console.log(entries)
                        // Store the data in state
                        setData(entries.items)
                    }
                )
                .catch(console.error)
            setLoading(false); // Set loading to false once data is fetched
          } catch (error) {
            console.error('Error fetching data:', error);
            setLoading(false);
          }
        };
    
        fetchData();
      }, []); // Empty dependency array ensures the effect runs only once

    return (
        <React.Fragment>
            <Banner/>
            <section className="section-best-estate"> 
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <Title title={"Välkomna till paradiset"} description={"Din mäklare på Costa del Sol" }size= {2}/>
                            <p>
                            Det är många som drömmer om ett boende i Spanien och Costa del Sol. En del vill bo där permanent och andra vill ha ett semesterboende. Med cirka 320 soldagar per år, 
                            närheten till havet, stränder, milt klimat, berg att vandra i och fantastisk mat är det inte konstigt att många drömmer om ett boende på Costa del Sol.  Under vintersäsongen
                            kan du till och med åka skidor på nära håll! På Costa del Sol ligger de flesta städer ungefär en timme ifrån flygplatsen i Malaga så det är enkelt att resa till sitt boende 
                            när man landat. Vi på Plats i solen hjälper er gärna att hitta det perfekta boendet.</p>
                            <p>
                            Vi har ett brett utbud av olika typer av boenden som lägenheter, hus, parhus och nyproduktion på Costa del Sol. Vi ser till ditt behov och dina krav av boendet och vårt mål 
                            är att hitta det perfekta boendet just för dig.  Att ha i åtanke när man väljer boende är vad man vill ha nära till som golfbanor, stränder, skolor, affärer, restauranger etc. 
                            Våra agenters kunskap i dessa frågor är mycket hög och vi hjälper dig hitta rätt. Vi hjälper dig hela vägen med att hitta ditt drömboende.
                             </p>
                        </div>
                    </div>
                </div>
            </section>
            <section className="section-best-estate"> 
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <Title title={"Varför Andalusien och Spanien?"} />
                            <p>
                            Spanien är ett fantastiskt land att bo i och på solrika Costa del Sol är det extra underbart. Befolkningen är vänlig och man hälsar ofta när man möts på gatorna. Att vakna upp 
                            och för det mesta se den klarblå himlen, solen, havet och bergen gör så att man genast känner sig glad och pigg. </p>
                            <p>
                            Det som kan vara lite bökigt är språket men de flesta spanjorer blir nöjda över att man i alla fall försöker prata lite spanska och det går oftast att förstå varandra efter en 
                            liten stund. En annan sak som tar en del kraft och tid är det administrativa. Det kommer att krävas en del tid och arbete om man ska bo här permanent när man måste besöka kommun, 
                            polis, socialkontor etc. Det finns en hel del online tjänster men dom kan vara svåra att hitta och förstå om man inte kan bra spanska. Man kan även anlita någon som hjälper till 
                            men man måste fortfarande ha de papper och information som behövs för det ärende man har. 
                             </p>
                        </div>
                    </div>
                </div>
            </section>
            <section className="section-best-estate"> 
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <Title title={"Mat & Kultur"} />
                            <p>
                            Maten i Spanien är fantastisk. Grönsaker och frukter smakar underbart, det finns ett väldigt utbud utav färsk fisk och skaldjur. Spansk mat och tapas av olika slag kan man äta 
                            nästan överallt men det finns även ett stort utbud av mat från andra länder. </p>
                            <p>
                            I Andalusien finns det ett otroligt utbud utav kultur. Städer som Sevilla, Cadiz, Cordoba och Granada har så mycket intressant och vackert att se och uppleva.  Som flamenco i Sevilla, 
                            den fantastiska Mesquitan i Cordoba med sina målningar och pelare med rödvita mönster upptill eller Alhambras trädgårdar och dess palats. Runt Cadiz finns underbara sandstränder 
                            och här kan du äta alldeles färsk tonfisk vissa tider på åretMalaga erbjuder en mängd olika museer som Picassomuseet för den konstintresserade här finns och ett rikt teaterliv . 
                            Om man vill ta sig t ex till Madrid går det snabbtåg från Malaga och du kan vara i den Spanska pulserande huvudstaden på cirka 2, 5 timme. 
                             </p>
                        </div>
                    </div>
                </div>
            </section>
            <section className="section-best-estate"> 
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <Title title={"Stränder & Aktiviteter"} />
                            <p>
                            Costa del Sol består av stränder längs hela kusten och det finns stränder för alla smaker. En del är barnvänliga medans andra är bättre för ungdomar eller för vattensport av 
                            olika slag. Vill man ha lyx och flärd finns en hel del beach clubs som t ex Nikki Beach, Ocean beach i Marbella eller relativt nyöppnade Sublim Beach Club i Estepona. 
                            För den som vill ha det lite enklare och billigare men vara på en restaurang vid stranden finns det oändligt många Chiringuitos. Det är inte långt att åka på en dagstur till 
                            Atlantkusten till stränder i Tarifa, Bolonia, Zahara de los Atunes eller El Palmar.  </p>
                            <p>
                            För den som inte vill åka till stranden finns det mängder av andra aktiviteter som padel, golf, kitesurfing, vandringar i bergen eller i floder. På vintern kan man åka skidor 
                            i Sierra Nevada som ligger cirka en timmes bilresa från Malaga. Skidsäsongen är normalt mellan december och april .
                            </p>
                        </div>
                    </div>
                </div>
            </section>
            {loading ? (
                <p>Loading data...</p>
            ) : (
                <FlatList properties={data} />
            )}
            {/* <BestFlatList/> */}
            <Subscribe/>
            <Info/>
            <References/>
        </React.Fragment>
    )
}

export default Home;